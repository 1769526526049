@font-face {
  font-family: "myFont1";
  src: url("fonts/Satoshi-Regular.woff") format("woff");
}

@font-face {
  font-family: "myFont2";
  src: url("fonts/Satoshi-Italic.woff") format("woff");
}


html{
    scroll-behavior: smooth;
    font-size: 16px;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "myFont1";
    scroll-behavior: smooth;
    overflow-x: hidden;
}
/* Contenedor del loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #d3eaf2; /* Fondo de la pantalla de carga */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  transition: opacity 1s ease, visibility 1s ease;
}

/* Animación de rotación */
.rotating-logo {
  width: 8em; /* Tamaño del logo */
  height: auto;
  animation: spin 2s linear infinite; /* Animación de rotación */
}

/* Definición de la animación */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*secciones*/
/* Contenedor principal */
.common-background {
  background-color: #f6eee4;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden; 
}

/* Navegación */
/* Contenedor principal del nav */
nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent; /* Fondo transparente */
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  padding: 0 7rem;
  box-sizing: border-box;
  overflow-y: hidden;
}

/* Contenedores de enlaces */
#div1, #div2 {
  display: flex;
  gap: 8rem;
}

/* Logo */
#logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  height: auto;
  margin: 0;
  padding: 0;

}

#logo img {
  width: 100%;
  height: auto;
}

/* Estilo de los enlaces */
#div1 a, #div2 a {
  text-decoration: none;
  color: black;
  font-size: 2.3rem;
  white-space: nowrap;
  transition: letter-spacing 0.4s ease-in-out;
}

#div1 a:hover,
#div2 a:hover {
  
  letter-spacing: 1px;
}


/* Mostrar nombre */
#profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem 2rem;
  background-color: #fdf8f4; /* Fondo claro */
}

#profile-container {
  display: flex;
  flex-direction: row; /* Coloca los elementos en fila */
  justify-content: space-between; /* Espacia los nombres en los lados */
  align-items: center; /* Alinea verticalmente los elementos */
  max-width: 800px; /* Limita el ancho del contenedor */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 2rem 0;
  margin-top: 10vh;
}

.nombre,
.apellido {
  font-size: 2.8rem; /* Tamaño del texto */
  font-weight: bold;
  color: #c94136; /* Color del texto */
  margin: 0; /* Elimina márgenes adicionales */
  overflow-x: visible;
}

#foto {
  display: flex;
  justify-content: center; /* Centra la imagen */
  align-items: center;
  width: 100%;
  max-width: 600px; /* Tamaño máximo de la imagen */
  margin: 0 2rem; /* Espaciado horizontal */
}

#foto img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Sección de texto */
.texto1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 2rem;
}

.texto1 h2 {
  font-family: "myFont2";
  font-weight: lighter;
  font-size: 3rem;
  color: #232323;
  margin: 0.5rem 0;
}

.texto1 h3 {
  color: #ad332a;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}
/* Media query para tu pantalla */

@media (min-width: 1300px) and (max-width: 2100px) {
  .texto1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative; /* Cambiamos a posición absoluta */
    bottom: 25vh; /* Ajusta la altura */
    left: 30vw; /* Posiciona cerca del apellido */
    transform: translateY(-50%); /* Centra el texto verticalmente respecto a su propio contenedor */
    text-align: left;
    font-size: 18rem;
  }
  #foto img {
    width: 100%; /* Imagen responsiva */
    max-width: 40vh;
    border-radius: 10px; /* Bordes redondeados */
    margin: 0; /* Sin márgenes adicionales */
  }
  #logo img{
    max-width: 80%;
  }
  #div1 a, #div2 a {
    font-size: 1.8rem;
  }
}




/* Ajustes responsivos */
@media (max-width: 768px) {
  /* Contenedor principal */
  #profile-section {
    padding-top: calc(8vh); /* Espacio justo debajo del nav */
    display: flex;
    flex-direction: column; /* Apila los elementos */
    align-items: center;
    text-align: center; /* Textos centrados */
  }

  /* Contenedor de perfil */
  #profile-container {
    display: flex;
    flex-direction: column; /* Alinea todo en columna */
    align-items: center;
    width: 100%;
    max-width: 400px;
  }

  /* Nombre */
  .nombre {
    margin: 0; /* Sin márgenes adicionales */
    padding-bottom: 0.8rem; /* Elimina cualquier relleno entre nombre e imagen */
    font-size: 1.5rem; /* Tamaño del texto */
    font-weight: bold;
    color: #c94136;
  }

  /* Imagen */
  #foto img {
    width: 90%; /* Imagen responsiva */
    max-width: 300px;
    border-radius: 10px; /* Bordes redondeados */
    margin: 0; /* Sin márgenes adicionales */
  }

  /* Ajuste para pegar el nombre a la imagen */
  #foto {
    margin-top: -0.2rem; /* Ajusta margen superior para "pegar" al nombre */
  }

  /* Apellido */
  .apellido {
    margin: 0; /* Sin márgenes adicionales */
    padding-top: 0.5rem; /* Separación solo debajo de la imagen */
    font-size: 1.5rem;
    color: #c94136;
  }

  /* Navegación */
  nav {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fdf8f4; /* Fondo blanco semitransparente */
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem; /* Reduce el padding */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  }

  /* Logo */
  #logo {
    max-width: 40px; /* Tamaño compacto del logo */
  }

  #logo img {
    width: 100%;
    height: auto;
  }

  /* Enlaces de navegación */
  #div1, #div2 {
    flex: 1 1 auto;
    display: flex;
    justify-content: center; /* Centra los enlaces */
    gap: 1rem; /* Espaciado entre enlaces */
  }

  #div1 a, #div2 a {
    font-size: 1rem; /* Ajusta el tamaño de fuente */
    color: black;
    text-decoration: none;
    white-space: nowrap;
  }
}




/* Estilos iniciales y animación */
.fade-up {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.5s ease, transform 1.5s ease;
  }
  
  .fade-up.visible {
    opacity: 1;
    transform: translateY(0);
  }
/* SIGUIENTE SECCIÓN */
/* Contenedor principal */
/* Contenedor principal */
.different-background {
  position: relative;
  background-color: #ad332a;
  min-height: 100vh;
  width: 100%;
  padding: 5vh 10%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tres columnas: izquierda, centro, derecha */
  grid-template-rows: auto auto auto auto; /* Filas automáticas */
  gap: 2rem; /* Espaciado entre elementos */
  align-items: center;
}

/* Front-end (arriba y centrado) */
.keywordfrontend {
  grid-column: 1 / -1; /* Ocupa todas las columnas */
  text-align: center;
  margin-bottom: 2rem;
  overflow-x: visible;
}

.keywordfrontend h2 {
  color: #f6eee4;
  font-size: 7.5rem;
}

/* Skills superiores (SEO & Digital Marketing) */
.keywordseo {
  grid-column: 1 / 2; /* Primera columna */
  text-align: left;
  color: #f6eee4;
  font-size: 4rem;
}

.keywordmarketing {
  grid-column: 3 / 4; /* Última columna */
  text-align: right;
  color: #f6eee4;
  font-size: 3.5rem;
}

/* Barra centrada en la misma fila */
.bar {
  grid-column: 2 / 3; /* Columna del medio */
  grid-row: 2; /* Mismo nivel que las skills superiores */
  width: 60%;
  height: 5px;
  margin: 0 auto;
  background-color: #f6eee4;
}

/* Skills inferiores (Detail & Creativity) */
.detail {
  grid-column: 1 / 2; /* Primera columna */
  text-align: left;
  color: #f6eee4;
  font-size: 3.5rem;
}

.creativity {
  grid-column: 3 / 4; /* Última columna */
  text-align: right;
  color: #f6eee4;
  font-size: 3.5rem;
}

/* AND centrado en la misma fila */
.and {
  grid-column: 2 / 3; /* Columna del medio */
  grid-row: 3; /* Mismo nivel que las skills inferiores */
  text-align: center;
  color: #f6eee4;
}

.and h2 {
  color: #f6eee4;
  font-size: 5rem;
  margin: 0;
}

/* Loader al final, centrado */


/* Responsividad */
@media (min-width:1250px){
  
  .keywordfrontend h2 {
    color: #f6eee4;
    font-size: 7.2rem;
  }
  
  /* Skills superiores (SEO & Digital Marketing) */
  .keywordseo {
    grid-column: 1 / 2; /* Primera columna */
    text-align: left;
    color: #f6eee4;
    font-size: 3.3rem;
  }
  
  .keywordmarketing {
    grid-column: 3 / 4; /* Última columna */
    text-align: right;
    color: #f6eee4;
    font-size: 3.3rem;
  }
  
  
  /* Skills inferiores (Detail & Creativity) */
  .detail {
    grid-column: 1 / 2; /* Primera columna */
    text-align: left;
    color: #f6eee4;
    font-size: 3rem;
  }
  
  .creativity {
    grid-column: 3 / 4; /* Última columna */
    text-align: right;
    color: #f6eee4;
    font-size: 3rem;
  }
  
  /* AND centrado en la misma fila */
  .and {
    grid-column: 2 / 3; /* Columna del medio */
    grid-row: 3; /* Mismo nivel que las skills inferiores */
    text-align: center;
    color: #f6eee4;
  }
  
  .and h2 {
    color: #f6eee4;
    font-size: 5rem;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .different-background {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Dos columnas: izquierda y derecha */
    grid-template-rows: auto auto auto; /* Filas automáticas */
    gap: 1rem; /* Espaciado entre elementos */
    padding: 5vh 2rem; /* Ajusta el padding para pantallas pequeñas */
    align-items: center;
    text-align: center;
  }

  /* Front-end (ocupa toda la fila superior) */
  .keywordfrontend {
    grid-column: 1 / -1; /* Ocupa las dos columnas */
    text-align: center;
    margin-bottom: 0;
  }

  .keywordfrontend h2 {
    font-size: 2.5rem; /* Reduce el tamaño del texto */
    color: #f6eee4;
  }

  /* Skills superiores */
  .keywordseo {
    grid-column: 1 / 2; /* Primera columna */
    font-size: 1.2rem; /* Ajusta tamaño del texto */
    text-align: center; /* Centra el texto */
    word-wrap: break-word; /* Evita overflow */
  }

  .keywordmarketing {
    grid-column: 2 / 3; /* Segunda columna */
    font-size: 1.2rem; /* Ajusta tamaño del texto */
    text-align: center; /* Centra el texto */
    word-wrap: break-word; /* Evita overflow */
  }

  /* Barra centrada */
  .bar {
    grid-column: 1 / -1; /* Ocupa toda la fila */
    width: 60%; /* Reduce el ancho */
    height: 2px;
    margin: 1rem auto; /* Espaciado vertical */
    background-color: #f6eee4;
  }

  /* Skills inferiores */
  .detail {
    grid-column: 1 / 2; /* Primera columna */
    font-size: 1.2rem; /* Ajusta tamaño del texto */
    text-align: center; /* Centra el texto */
    word-wrap: break-word; /* Evita overflow */
  }

  .creativity {
    grid-column: 2 / 3; /* Segunda columna */
    font-size: 1.2rem; /* Ajusta tamaño del texto */
    text-align: center; /* Centra el texto */
    word-wrap: break-word; /* Evita overflow */
  }

  /* AND centrado entre habilidades inferiores */
  .and {
    grid-column: 1 / -1; /* Ocupa las dos columnas */
    grid-row: 4; /* Mismo nivel que las habilidades inferiores */
    text-align: center;
    margin-top: -1rem; /* Ajusta el espaciado vertical */
  }

  .and h2 {
    font-size: 2rem; /* Reduce el tamaño del texto */
    margin: 0;
    color: #f6eee4;
  }
}


/*About Me Section*/
.thirdbackground {
  position: relative;
  background-color: #d3eaf2;
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr; /* Dos columnas iguales */
  grid-template-rows: auto auto auto auto; /* Dos filas ajustables */
  align-items: start;
  padding: 5vw;
  gap: 2rem;
  overflow: hidden; /* Evita overflow */
}

.fotoaboutme {
  grid-column: 1 / 2; /* Ubica la imagen en la primera columna */
  grid-row: 1 / span 4; /* La imagen ocupa toda la altura */
  display: flex;
  justify-content: flex-start; /* Alinea la imagen a la izquierda */
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.fotoaboutme img {
  max-width: 80%; /* Reduce el tamaño de la imagen */
  height: auto; /* Mantén proporciones */
  object-fit: contain; /* Escala correctamente */
  border-radius: 10px;
  display: block;
  margin-top: 5vh;
}

.aboutmetitle {
  grid-column: 2 / 3; /* Ubica el título en la segunda columna */
  grid-row: 1; /* En la primera fila */
  font-size: calc(2rem + 2vw); /* Escala dinámicamente */
  font-family: myFont1;
  text-align: left; /* Alinea el texto a la izquierda */
  color: #ad332a;
}

.introduccion {
  grid-column: 2 / 3; /* Ubica la introducción debajo del título */
  grid-row: 2; /* Segunda fila */
  font-size: calc(1rem + 0.5vw); /* Escala dinámicamente */
  text-align: justify; /* Mejora la lectura */
  color: #ad332a;
}

.textoaboutme {
  grid-column: 2 / 3; /* Ubica el texto debajo de la introducción */
  grid-row: 3; /* Tercera fila */
  font-size: calc(1rem + 0.5vw); /* Escala dinámicamente */
  text-align: justify; /* Mejora la lectura */
  color: #73201a;
}

.technologies {
  grid-column: 2 / 3; /* Ubica las tecnologías debajo del texto */
  grid-row: 4; /* Cuarta fila */
  font-size: calc(1.6rem + 0.3vw); /* Escala dinámicamente */
  text-align: center; /* Centra el texto */
  color: #ad332a;
  overflow-y: hidden;
}

.technologies-grid {
  grid-column: 2 / 3; /* Mantén las tecnologías en la segunda columna */
  grid-row: 5; /* Quinta fila */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Columnas adaptables */
  gap: 1rem; /* Espaciado uniforme */
  justify-items: center; /* Centra elementos en las columnas */
  margin-top: 2rem;
  overflow-y: hidden;
}

.tech-item {
  border-radius: 5px;
  padding: 0.5rem; /* Espaciado interno reducido */
  font-size: 1.5rem;
  text-align: center; /* Centra el texto */
  transition: transform 0.2s, box-shadow 0.2s;
  overflow-y: hidden;
}

.tech-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .thirdbackground {
    display: flex; /* Cambia a flexbox para organizar elementos en columna */
    flex-direction: column;
    align-items: center; /* Centra todo horizontalmente */
    padding: 5vw 2rem; /* Ajusta el padding */
    gap: 1.5rem; /* Reduce el espaciado entre elementos */
  }

  .fotoaboutme {
    order: 1; /* Coloca la imagen en la parte superior */
    width: 100%; /* Ocupa todo el ancho */
    display: flex;
    justify-content: center; /* Centra la imagen */
  }

  .fotoaboutme img {
    max-width: 50%; /* Reduce el tamaño de la imagen */
    margin-top: 2rem; /* Añade espacio superior */
  }

  .aboutmetitle {
    order: 2; /* Título después de la imagen */
    font-size: 2rem; /* Reduce el tamaño del texto */
    text-align: center; /* Centra el título */
  }

  .introduccion {
    order: 3; /* Introducción después del título */
    font-size: 1rem; /* Ajusta el tamaño del texto */
    text-align: justify; /* Mantén texto justificado */
  }

  .textoaboutme {
    order: 4; /* Texto después de la introducción */
    font-size: 1rem; /* Ajusta el tamaño del texto */
    text-align: justify;
  }

  .technologies {
    order: 5; /* Tecnologías después del texto */
    font-size: 1.2rem; /* Reduce el tamaño del texto */
    text-align: center;
  }

  .technologies-grid {
    order: 6; /* Mantén la cuadrícula de tecnologías al final */
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Reduce tamaño de columnas */
    gap: 0.8rem; /* Ajusta el espaciado entre tecnologías */
  }
  .tech-item{
    font-size: 1rem;
  }
}

/*experience section*/
.fourth-background {
  background-color: #ad332a;
  width: 100%;
  min-height: 100vh; /* Asegura que ocupe al menos el alto de la pantalla */
  display: flex;
  flex-direction: column; /* Coloca los elementos en columna */
  align-items: center; /* Alinea elementos horizontalmente al centro */
  justify-content: center; /* Centra los elementos verticalmente */
  padding: 5vh 5vw; /* Relleno interno para márgenes */
  gap: 5vh; /* Espaciado entre los elementos */
  box-sizing: border-box; /* Incluye padding en el cálculo de dimensiones */
}

.experience-title h2 {
  text-align: center;
  font-size: 3rem; /* Tamaño adaptable */
  color: #d3eaf2;
  font-family: "myFont2";
  margin: 0; /* Elimina márgenes extra */
}

#experience-info {
  display: grid; /* Usa grid para estructurar los cuadros */
  grid-template-columns: repeat(2, 1fr); /* Dos columnas iguales */
  gap: 3vw; /* Espaciado entre columnas */
  width: 100%; /* Ajusta al ancho total del contenedor */
}

.expinf1,
.expinf2,
.expinf3,
.expinf4 {
  background-color: #d3eaf2;
  padding: 1.5rem;
  border-radius: 20px;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sombra para mejor visibilidad */
  transition: transform 0.3s;
}

.expinf1:hover,
.expinf2:hover,
.expinf3:hover,
.expinf4:hover {
  transform: translateY(-5px); /* Pequeño efecto al pasar el cursor */
}

.experience-path {
  position: relative;
  width: 100%; /* Asegura que ocupe el ancho disponible */
  height: auto;
}

.path-line {
  width: 100%; /* El SVG ocupa el 100% del contenedor */
  height: auto;
}

#marks {
  position: absolute;
  top: 0; /* Ajusta los marcadores al inicio del contenedor */
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between; /* Espaciado uniforme entre marcadores */
  align-items: center; /* Alineación vertical */
}

.mark-1,
.mark-2,
.mark-3,
.mark-4 {
  background: #d3eaf2;
  color: #c24a41;
  font-size: 1.2rem;
  font-weight: bold;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .fourth-background {
    padding: 8vh 4vw; /* Reduce el padding en pantallas pequeñas */
    gap: 3vh; /* Reduce el espacio entre elementos */
  }

  #experience-info {
    grid-template-columns: 1fr; /* Una columna para pantallas pequeñas */
    gap: 2rem; /* Reduce el espaciado */
  }

  .expinf1,
  .expinf2,
  .expinf3,
  .expinf4 {
    font-size: 0.9rem; /* Reduce el tamaño de fuente */
  }

  .experience-title h2 {
    font-size: 2.5rem; /* Reduce tamaño del título */
  }

  .path-line {
    width: 100%; /* SVG ajustado al contenedor */
    height: auto; /* Mantiene proporciones */
  }

  .mark-1,
  .mark-2,
  .mark-3,
  .mark-4 {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem; /* Ajusta los marcadores */
  }
}

/*Contact section*/
#contact{
  padding: 15% 0;
  background-color: #d3eaf2;
  max-width: 100vw;
  min-height: 100vh;
}
.contact-section {
    display:block;
    text-align: center;
    align-items: center;
    padding: 4rem 2rem;
    font-family: 'Georgia', serif;
  }
  
  .contact-section h2 {
    font-size: 4rem;
    font-weight: bold;
    color: #111;
  }
  
  .contact-section .highlight {
    color: #ff5733; /* Un naranja vibrante */
    font-style: italic;
  }
  
  .contact-section .email {
    font-size: 3rem;
    color: #ff5733;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .contact-section .action {
    margin-top: 1rem;
    font-size: 1.6rem;
    color: #333;
  }
  
  .contact-section .arrow {
    font-size: 2rem;
    color: #111;
    margin-right: 0.5rem;
  }
  
  .contact-section .social-links {
    margin-top: 3rem;
    font-size: 1.4rem;
  }
  
  .contact-section .social-links a {
    margin: 0 1rem;
    text-decoration: none;
    color: #111;
    transition: color 0.3s ease;
  }
  
  .contact-section .social-links a:hover {
    color: #ff5733;
  }
  @media (max-width: 768px) {
    #contact {
      padding: 55% 4vw;
      min-height: auto;
    }
    .contact-section {
      padding: 2rem 1rem; 
    }
    .contact-section h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .contact-section .email {
      font-size: 1.4rem;
    }
    
    .contact-section .action {
      margin-top: 1rem;
      font-size: 1.3rem;
    }
    
    .contact-section .arrow {
      font-size: 1.3rem;
      margin-right: 0.5rem;
    }
    
    .contact-section .social-links {
      margin-top: 3rem;
      font-size: 1.2rem;
    }
  }
